import * as Sentry from "@sentry/nuxt";

Sentry.init({
    // dsn: "https://2c31a0929ddc2925c03b8f91d24040e0@o4504170234183680.ingest.us.sentry.io/4508058538868736",
    dsn: "https://06bdbe7f9682f1ecdb9f2486ebd97b52@o4507661325565952.ingest.de.sentry.io/4507661446152272",
    integrations: [
        Sentry.replayIntegration()
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracing: true,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    beforeSend(event) {
        // const config = useRuntimeConfig()

        // if (config.public.APP_ENV !== 'production') {
        //     return null;
        // }

        const ignoredMessages = [
            'Minified invariant #4412',
            'Maximum call stack size exceeded',
            'too much recursion',
            'is not valid JSON',
            'Cookiebot'
        ];

        if (event.exception && event.exception.values) {
            const shouldIgnore = event.exception.values.some((exception) =>
                ignoredMessages.some(message => exception.value.includes(message))
            );

            if (shouldIgnore) {
                return null; // Ігнорувати ці помилки
            }
        }

        return event; // Відправляти всі інші помилки в Sentry
    },
});
